<template>
  <div>
    <div class="mt-1 mb-1">
      Thông tin giấy tờ tiếp nhận bổ sung
    </div>
    <vue-good-table
      ref="vgTable"
      class="mt-1"
      :columns="getColumns()"
      :rows="form"
    >
      <div
        slot="emptystate"
        class="text-center"
      >
        Không có dữ liệu
      </div>
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.field == 'tenGiayTo'">
          <validation-provider
            v-slot="{ errors }"
            :key="`tenGiayTo_${props.index}`"
            :rules="{ required: true }"
            name="Tên giấy tờ"
          >
            <b-form-input
              id="input-1"
              v-model="form[props.index].tenGiayTo"
              type="text"
              placeholder="Nhập tên giấy tờ"
              :disabled="type === 'TIEP_NHAN_BO_SUNG' ? true : false"
              required
            />
            <span class="label-noti-validate">{{ errors[0] }}</span>
          </validation-provider>
        </span>
        <span v-else-if="props.column.field == 'banChinh'">
          <validation-provider
            v-slot="{ errors }"
            :key="`banChinh_${props.index}`"
            :rules="{ required: true }"
            name="Bản chính"
          >
            <b-form-input
              id="input-1"
              v-model.number="form[props.index].banChinh"
              v-block-e
              v-format-positive-integers
              v-is-int
              v-format-number-null
              type="number"
              placeholder="Nhập bản chính"
              :disabled="type === 'TIEP_NHAN_BO_SUNG' ? true : false"
              required
            />
            <span class="label-noti-validate">{{ errors[0] }}</span>
          </validation-provider>
        </span>
        <span v-else-if="props.column.field == 'banSao'">
          <validation-provider
            v-slot="{ errors }"
            :key="`banSao_${props.index}`"
            :rules="{ required: true }"
            name="Bản sao"
          >
            <b-form-input
              id="input-1"
              v-model.number="form[props.index].banSao"
              v-block-e
              v-format-positive-integers
              v-is-int
              v-format-number-null
              type="number"
              placeholder="Nhập bản sao"
              :disabled="type === 'TIEP_NHAN_BO_SUNG' ? true : false"
              required
            />
            <span class="label-noti-validate">{{ errors[0] }}</span>
          </validation-provider>
        </span>
        <span v-else-if="props.column.field == 'banPhoto'">
          <validation-provider
            v-slot="{ errors }"
            :key="`banPhoTo_${props.index}`"
            :rules="{ required: true }"
            name="Bản photo"
          >
            <b-form-input
              id="input-1"
              v-model.number="form[props.index].banPhoto"
              v-block-e
              v-format-positive-integers
              v-is-int
              v-format-number-null
              type="number"
              placeholder="Nhập bản photo"
              :disabled="type === 'TIEP_NHAN_BO_SUNG' ? true : false"
              required
            />
            <span class="label-noti-validate">{{ errors[0] }}</span>
          </validation-provider>
        </span>
        <span v-else-if="props.column.field == 'dsHoSoGiayToFileDinhKem'">
          <div
            v-if="form[props.index].dsHoSoGiayToFileDinhKem && form[props.index].dsHoSoGiayToFileDinhKem.length > 0"
            class="grap-12 mb-1"
          >
            <div
              v-for="(mdtk, index) in form[props.index].dsHoSoGiayToFileDinhKem"
              :key="index"
            >
              - <a
                href="javascript:;"
                class="mr-1"
                @click="downloadFile(mdtk)"
              >{{ mdtk.tenFileDinhKem }}</a>
              <trash-2-icon
                v-b-tooltip.hover.v-secondary
                title="Xoá"
                size="16"
                class="custom-class cursor-pointer"
                @click="deleteFile(index, props.index)"
              />
            </div>
          </div>
          <div>
            <b-button
              variant="primary"
              class="d-flex"
              :disabled="!isLoadedFile"
              size="sm"
              @click="(e) => $refs[`fileInput_${props.index}`].click()"
            >
              <b-spinner
                v-if="!isLoadedFile"
                small
                label="Spinning"
                style="margin-right: 5px;"
              />
              Tải file
            </b-button>
            <input
              :ref="`fileInput_${props.index}`"
              type="file"
              class="d-none"
              multiple
              accept="application/msword, application/vnd.ms-excel, application/pdf, .docx, .xlsx, application/x-rar-compressed, image/*"
              @input="e => changeFile(e, props.index)"
            >
          </div>
        </span>
        <span v-else-if="props.column.field == 'actions'">
          <plus-square-icon
            v-if="props.index + 1 == form.length"
            v-b-tooltip.hover.v-secondary
            title="Thêm"
            size="16"
            class="custom-class cursor-pointer mr-1"
            @click="addCommon"
          />
          <trash-2-icon
            v-if="form.length > 1"
            v-b-tooltip.hover.v-secondary
            title="Xóa"
            size="16"
            class="custom-class cursor-pointer"
            @click="deleteCommon(props.index)"
          />
        </span>
        <span v-else-if="props.column.field == 'STT'">
          {{ rowNumber(props) }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>
<script>
import END_POINTS from '@/api/endpoints'
import {
  VBTooltip,
  BFormInput,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import { extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import {
  PlusSquareIcon,
  Trash2Icon,
} from 'vue-feather-icons'
import { removeDiacritical } from '@/utils/index'

extend('required', {
  ...required,
  message: 'Vui lòng nhập thông tin',
})

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BFormInput,
    BButton,
    BSpinner,
    PlusSquareIcon,
    Trash2Icon,
    VueGoodTable,
  },
  props: {
    dataForm: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      columns: [
        {
          label: 'STT',
          thClass: 'text-center custom-style',
          tdClass: 'text-center vertical-align-middle',
          width: '50px',
          field: 'STT',
          sortable: false,
        },
        {
          label: 'Tên giấy tờ',
          field: 'tenGiayTo',
          thClass: 'text-center',
          width: '300px',
          tdClass: 'vertical-align-middle',
        },
        {
          label: 'Bản chính',
          field: 'banChinh',
          width: '150px',
          thClass: 'text-center',
          tdClass: 'vertical-align-middle text-center',
        },
        {
          label: 'Bản sao',
          field: 'banSao',
          width: '100px',
          thClass: 'text-center',
          tdClass: 'vertical-align-middle text-center',
        },
        {
          label: 'Bản photo',
          field: 'banPhoto',
          width: '150px',
          thClass: 'text-center',
          tdClass: 'vertical-align-middle text-center',
        },
        {
          label: 'File đính kèm',
          field: 'dsHoSoGiayToFileDinhKem',
          thClass: 'text-center',
          tdClass: 'vertical-align-middle',
        },
        {
          label: 'Thao tác',
          field: 'actions',
          width: '100px',
          thClass: 'text-center',
          tdClass: 'text-center vertical-align-middle',
        },
      ],
      rows: [],
      dv: [],
      gt: [],
      form: {
        thongTinBoSung: [],
      },
      isCreate: false,
      errorFileSize: null,
      isLoadedFile: true,
      isShow: false,
    }
  },
  computed: {
  },
  mounted() {
  },
  created() {
    this.form = this.dataForm
  },
  methods: {
    rowNumber(row) {
      return row.index + 1
    },
    deleteCommon(idx) {
      this.form = this.form.filter((item, index) => index !== idx)
    },
    getColumns() {
      return this.type !== 'YEU_CAU_BO_SUNG' ? this.columns : this.columns.filter(item => item.field !== 'dsHoSoGiayToFileDinhKem')
    },
    addCommon() {
      const form = {
        tenGiayTo: null,
        banChinh: 0,
        banSao: 0,
        banPhoto: 0,
        dsHoSoGiayToFileDinhKem: [],
      }
      this.form.push(form)
    },
    onFilterSelect(options, search) {
      return options.filter(item => removeDiacritical(item.name).indexOf(removeDiacritical(search.trim())) >= 0)
    },
    getDataBoSung() {
      return this.form
    },
    changeFile(e, index) {
      const file = Array.from(e.target.files || e.dataTransfer.files)
      const numFile = 20 - this.form[index].dsHoSoGiayToFileDinhKem.length
      if (file.length > numFile) {
        this.$toast.error('Tệp đính kèm không vượt quá 20!')
      }
      // eslint-disable-next-line prefer-const
      let fileName = []
      // eslint-disable-next-line prefer-const
      let fileNameSize = []
      // eslint-disable-next-line prefer-const
      let fileResult = []
      // eslint-disable-next-line prefer-const
      let filesAttck = []
      // eslint-disable-next-line prefer-const
      let filesNot = []
      file.forEach(item => {
        if (!this.form[index].dsHoSoGiayToFileDinhKem.find(ele => ele.tenFileDinhKem.indexOf(item.name) >= 0) && item.size < 26214400) {
          if (['application/msword', 'application/vnd.ms-excel', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/x-rar-compressed', 'image/png', 'image/jpg', 'image/gif', 'image/bmp'].includes(item.type)) {
            fileResult.push(item)
          } else {
            filesNot.push(item.name)
          }
        } else if (item.size > 26214400) {
          fileNameSize.push(item.name)
        } else {
          fileName.push(item.name)
        }
      })
      fileResult = fileResult.filter((item, idx) => idx < numFile)
      filesAttck.push(...fileResult)
      if (fileName.length > 0) {
        // eslint-disable-next-line no-unused-vars
        let fileNameError = ''
        fileName.forEach(item => {
          fileNameError += `- ${item}\n`
        })
        this.$toast.error(`Tệp đính kèm bị trùng: \n ${fileNameError}`)
      }
      if (fileNameSize.length > 0) {
        // eslint-disable-next-line no-unused-vars
        let fileNameSizeError = ''
        fileNameSize.forEach(item => {
          fileNameSizeError += `- ${item}\n`
        })
        this.$toast.error(`Tệp đính kèm:\n ${fileNameSizeError} dung lượng lớn hơn 25 MB`)
      }
      if (filesNot.length > 0) {
        // eslint-disable-next-line no-unused-vars
        let filesNotError = ''
        filesNot.forEach(item => {
          filesNotError += `- ${item}\n`
        })
        this.$toast.error(`Tệp đính kèm:\n ${filesNotError} không đúng định dạng`)
      }
      if (filesAttck.length > 0) {
        // eslint-disable-next-line prefer-const
        let formData = new FormData()
        filesAttck.forEach(item => {
          formData.append('listFileData', item)
        })
        this.$axios.post(END_POINTS.UPLOAD_FILE.MULTI, formData, false).then(res => {
          if (res.data) {
            const data = res.data.map(item => ({
              tenFileDinhKem: item.fileName,
              pathFileDinhKiem: item.objectName,
            }))
            this.form[index].dsHoSoGiayToFileDinhKem.push(...data)
          }
        })
      }
    },
    deleteFile(index, idxRow) {
      this.form[idxRow].dsHoSoGiayToFileDinhKem = this.form[idxRow].dsHoSoGiayToFileDinhKem.filter((item, idx) => idx !== index)
    },
    downloadFile(data) {
      this.$axios.post(END_POINTS.UPLOAD_FILE.DOWNLOAD, { path: data.path }).then(res => {
        if (res.data?.succeeded) {
          // eslint-disable-next-line prefer-const
          let a = document.createElement('a')
          a.href = `data:application/octet-stream;base64,${res.data.fileData}`
          a.download = res.data.fileName
          a.setAttribute('target', '_blank')
          a.click()
        }
      })
    },
  },
}
</script>
