<template>
  <div class="mt-1">
    <div class="d-flex justify-content-between">
      <div>Tệp đính kèm kết quả</div>
      <div>
        <b-button
          variant="primary"
          class="d-flex"
          size="sm"
          @click="(e) => $refs.fileInput.click()"
        >
          <b-spinner
            v-if="false"
            small
            label="Spinning"
            style="margin-right: 5px;"
          />
          Tải file
        </b-button>
        <input
          ref="fileInput"
          type="file"
          class="d-none"
          multiple
          accept="application/msword, application/vnd.ms-excel, application/pdf, .docx, .xlsx, application/x-rar-compressed, image/*"
          @change="changeFile"
        >
      </div>
    </div>
    <good-table
      ref="hoSo"
      class="mt-1"
      :pagination="false"
      :selectable="false"
      :columns="columns"
      :rows="tepKetQuaHoSo"
    >
      <template
        slot="custom-row"
        slot-scope="props"
      >
        <span v-if="props.props.column.field == 'tenFile'">
          <a
            href="javascript:;"
            @click="downloadFile(props.props.row)"
          >{{ `${props.props.row.tenFile}` }}</a>
        </span>
      </template>
      <template
        slot="actions"
        slot-scope="props"
      >
        <div class="text-center">
          <trash-2-icon
            v-b-tooltip.hover.v-secondary
            title="Xoá"
            size="16"
            class="custom-class cursor-pointer"
            @click="deleteFile(props.props.index)"
          />
        </div>
      </template>
    </good-table>
  </div>
</template>
<script>
import END_POINTS from '@/api/endpoints'
import {
  BButton,
  BSpinner,
  VBTooltip,
} from 'bootstrap-vue'
import { Trash2Icon } from 'vue-feather-icons'
import GoodTable from '@/components/GoodTable.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BButton,
    BSpinner,
    Trash2Icon,
    GoodTable,
  },
  props: {
    tepKetQuaHoSo: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      columns: [
        {
          label: 'STT',
          thClass: 'text-center custom-style',
          tdClass: 'text-center vertical-align-middle',
          width: '50px',
          field: 'STT',
          sortable: false,
        },
        {
          label: 'Tên file đính kèm',
          field: 'tenFile',
          thClass: 'text-center',
          tdClass: 'vertical-align-middle',
        },
        {
          label: 'Thao tác',
          field: 'actions',
          width: '100px',
          thClass: 'text-center',
          tdClass: 'text-center vertical-align-middle',
        },
      ],
    }
  },
  methods: {
    changeFile(e) {
      this.isLoadedFile = true
      const file = Array.from(e.target.files || e.dataTransfer.files)
      const numFile = 20 - this.tepKetQuaHoSo.length
      if (file.length > numFile) {
        this.$toast.error('Tệp đính kèm không vượt quá 20!')
      }
      // eslint-disable-next-line prefer-const
      let fileName = []
      // eslint-disable-next-line prefer-const
      let fileNameSize = []
      // eslint-disable-next-line prefer-const
      let fileResult = []
      // eslint-disable-next-line prefer-const
      let filesAttck = []
      // eslint-disable-next-line prefer-const
      let filesNot = []
      try {
        file.forEach(item => {
          if (!this.tepKetQuaHoSo.find(ele => ele.tenFile.indexOf(item.name) >= 0) && item.size < 26214400) {
            if (['application/msword', 'application/vnd.ms-excel', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/x-rar-compressed', 'image/png', 'image/jpg', 'image/gif', 'image/bmp'].includes(item.type)) {
              fileResult.push(item)
            } else {
              filesNot.push(item.name)
            }
          } else if (item.size > 26214400) {
            fileNameSize.push(item.name)
          } else {
            fileName.push(item.name)
          }
        })
        fileResult = fileResult.filter((item, index) => index < numFile)
        filesAttck.push(...fileResult)
        if (fileName.length > 0) {
          // eslint-disable-next-line no-unused-vars
          let fileNameError = ''
          fileName.forEach(item => {
            fileNameError += `- ${item}\n`
          })
          this.$toast.error(`Tệp đính kèm bị trùng: \n ${fileNameError}`)
        }
        if (fileNameSize.length > 0) {
          // eslint-disable-next-line no-unused-vars
          let fileNameSizeError = ''
          fileNameSize.forEach(item => {
            fileNameSizeError += `- ${item}\n`
          })
          this.$toast.error(`Tệp đính kèm:\n ${fileNameSizeError} dung lượng lớn hơn 25 MB`)
        }
        if (filesNot.length > 0) {
          // eslint-disable-next-line no-unused-vars
          let filesNotError = ''
          filesNot.forEach(item => {
            filesNotError += `- ${item}\n`
          })
          this.$toast.error(`Tệp đính kèm:\n ${filesNotError} không đúng định dạng`)
        }
        if (filesAttck.length > 0) {
          // eslint-disable-next-line prefer-const
          let formData = new FormData()
          filesAttck.forEach(item => {
            formData.append('listFileData', item)
          })
          this.$axios.post(END_POINTS.UPLOAD_FILE.MULTI, formData, false).then(res => {
            if (res.data) {
              const data = res.data.map(item => ({
                tenFile: item.fileName,
                path: item.objectName,
              }))
              this.tepKetQuaHoSo.push(...data)
            }
            this.isLoadedFile = true
          })
        }
      } finally {
        if (filesAttck.length < 0) {
          this.isLoadedFile = true
        }
      }
    },
    deleteFile(index) {
      this.tepKetQuaHoSo = this.tepKetQuaHoSo.filter((item, idx) => idx !== index)
    },
    downloadFile(data) {
      this.$axios.post(END_POINTS.UPLOAD_FILE.DOWNLOAD, { path: data.path }).then(res => {
        if (res.data?.succeeded) {
          // eslint-disable-next-line prefer-const
          let a = document.createElement('a')
          a.href = `data:application/octet-stream;base64,${res.data.fileData}`
          a.download = res.data.fileName
          a.setAttribute('target', '_blank')
          a.click()
        }
      })
    },
    getData() {
      return this.tepKetQuaHoSo
    },
  },
}
</script>
