<template>
  <div
    id="wfdesigner"
    class="overflow-hidden"
  />
</template>
<script>
import END_POINTS from '@/api/endpoints'
import { DOMAIN, API_VERSION } from '@/constants/constants'
import $ from 'jquery'

export default {
  data() {
    return {
      graphwidth: null,
      graphheight: null,
      wfdesigner: null,
      schemecode: null,
      processid: null,
      versionStr: 'Phiên bản',
      payload: {
        filter: {
          code: null,
          sort: '-Version',
        },
      },
      arrSchema: null,
      currentSchema: null,
      versionNow: '',
      hoSoId: null,
    }
  },
  mounted() {
    this.hoSoId = this.$route.query.id
    this.getDataHoSo()
  },
  methods: {
    getDataHoSo() {
      this.$axios.get(`${END_POINTS.TIEP_NHAN_HO_SO_TRUC_TIEP.CHI_TIET}?id=${this.hoSoId}`).then(res => {
        if (res.data?.succeeded) {
          this.detail = res.data.data
          this.createWorkflow()
        }
      })
    },
    createWorkflow() {
      this.graphwidth = $('.modal-dialog').width() - 80
      const graphminheight = $(window).height()
      this.graphheight = graphminheight
      this.wfdesigner = undefined
      this.schemecode = this.detail.schemeCode
      if (this.schemecode === undefined || this.schemecode === '') {
        this.schemecode = 'TestScheme'
      }
      this.processid = this.$route.query.id || ''
      $(window).resize(() => {
        if (window.wfResizeTimer) {
          clearTimeout(window.wfResizeTimer)
          window.wfResizeTimer = undefined
        }
        window.wfResizeTimer = setTimeout(() => {
          if (this.graphheight < this.graphminheight) this.graphheight = this.graphminheight
          this.wfdesignerRedraw()
        }, 150)
      })
      $(window).resize()
    },
    async wfdesignerRedraw(isdestroydata = false) {
      let data
      await this.$nextTick()
      if (this.wfdesigner !== undefined) {
        data = this.wfdesigner.data
        this.wfdesigner.destroy()
      }
      if (isdestroydata) {
        data = undefined
      }
      // eslint-disable-next-line no-undef
      this.wfdesigner = new WorkflowDesigner({
        name: 'simpledesigner',
        apiurl: `${DOMAIN}${API_VERSION}${END_POINTS.WORKFLOW_SCHEME_DESIGNERAPI.API_URL}`,
        mode: 'readonly',
        renderTo: 'wfdesigner',
        imagefolder: '/assets/workflow/images/',
        graphwidth: this.graphwidth,
        graphheight: this.graphheight,
        // notrendertoolbar: true,
        notshowwindows: true,
        disableobjectmovements: true,
      })
      if (data === undefined) {
        let isreadonly = false
        if (this.processid !== undefined && this.processid !== '') {
          isreadonly = true
        }
        const p = { schemecode: this.schemecode, processid: this.processid, readonly: isreadonly }
        if (this.wfdesigner.exists(p)) {
          this.wfdesigner.load(p)
        } else {
          this.wfdesigner.create()
        }
      } else {
        this.wfdesigner.data = data
        this.wfdesigner.render()
      }
    },
    getQueryString() {
      // eslint-disable-next-line prefer-const
      let queryString = {}
      const query = window.location.search.substring(1)
      const vars = query.split('&')
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=')
        // If first entry with this name
        if (typeof queryString[pair[0]] === 'undefined') {
          // eslint-disable-next-line prefer-destructuring
          queryString[pair[0]] = pair[1]
        } else if (typeof queryString[pair[0]] === 'string') {
          const arr = [queryString[pair[0]], pair[1]]
          queryString[pair[0]] = arr
        } else {
          queryString[pair[0]].push(pair[1])
        }
      }
      return queryString
    },
    renderExternalLink(href) {
      const link = document.createElement('link')
      link.type = 'text/css'
      link.href = href
      link.rel = 'stylesheet'
      document.body.appendChild(link)
      return link
    },
    renderExternalScript(src) {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = src
      script.async = true
      script.defer = true
      document.body.appendChild(script)
      return script
    },
  },
}
</script>
