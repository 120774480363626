<template>
  <div class="mt-1">
    <div class="custom-grid-xl-container">
      <b-form-group
        id="input-group-1"
        label="Ngày hẹn trả hiện tại"
        label-for="input-1"
        class="required grap-6"
      >
        <validation-provider
          v-slot="{ errors }"
          key="ngayHenTraHienTai"
          :rules="{ required: true }"
          name="ngayHenTraHienTai"
        >
          <date-picker
            v-model="data.ngayHenTraHienTai"
            type="datetime"
            class="w-100 date-picker"
            format="DD/MM/YYYY HH:mm"
            :disabled="true"
          />
          <span class="label-noti-validate">{{ errors[0] }}</span>
        </validation-provider>
      </b-form-group>
      <b-form-group
        id="input-group-1"
        label="Số ngày gia hạn"
        label-for="input-1"
        class="required grap-6"
      >
        <validation-provider
          v-slot="{ errors }"
          key="soNgayGiaHan"
          :rules="{ required: true }"
          name="Số ngày gia hạn"
        >
          <b-form-input
            id="input-1"
            v-model.number="data.soNgayGiaHan"
            v-block-e
            v-format-positive-integers
            type="number"
            placeholder="Nhập số ngày gia hạn"
            required
            :disabled="type == 'DUYET_GIA_HAN' || type == 'TU_CHOI_GIA_HAN'"
            @input="changeSoNgayGiaHan"
          />
          <span class="label-noti-validate">{{ errors[0] }}</span>
        </validation-provider>
      </b-form-group>
      <b-form-group
        id="input-group-1"
        label="Ngày hẹn trả mới"
        label-for="input-1"
        class="required grap-6"
      >
        <validation-provider
          v-slot="{ errors }"
          key="ngayHenTraMoi"
          :rules="{ required: true }"
          name="ngayHenTraMoi"
        >
          <date-picker
            v-model="data.ngayHenTraMoi"
            type="datetime"
            class="w-100 date-picker"
            format="DD/MM/YYYY HH:mm"
            :disabled="true"
          />
          <span class="label-noti-validate">{{ errors[0] }}</span>
        </validation-provider>
      </b-form-group>
    </div>
    <div>
      <b-form-group
        id="input-group-1"
        label=""
        label-for="input-1"
      >
        <div class="d-flex justify-content-between">
          <div>Văn bản đính kèm</div>
          <div
            v-if="type !== 'DUYET_GIA_HAN' && type !== 'TU_CHOI_GIA_HAN'"
            class="grap-2"
          >
            <b-button
              variant="primary"
              class="d-flex"
              :disabled="!isLoadedFile"
              size="sm"
              @click="(e) => $refs.fileInput.click()"
            >
              <b-spinner
                v-if="!isLoadedFile"
                small
                label="Spinning"
                style="margin-right: 5px;"
              />
              Tải file
            </b-button>
            <input
              ref="fileInput"
              type="file"
              class="d-none"
              multiple
              accept="application/msword, application/vnd.ms-excel, application/pdf, .docx, .xlsx, application/x-rar-compressed, image/*"
              @change="changeFile"
            >
          </div>
        </div>
        <good-table
          ref="hoSo"
          class="mt-1"
          :pagination="false"
          :selectable="false"
          :columns="getColumns"
          :rows="data.dsFileDinhKem"
        >
          <template
            slot="custom-row"
            slot-scope="props"
          >
            <span v-if="props.props.column.field == 'tenFile'">
              <a
                href="javascript:;"
                @click="downloadFile(props.props.row)"
              >{{ `${props.props.row.tenFile}` }}</a>
            </span>
          </template>
          <template
            slot="actions"
            slot-scope="props"
          >
            <div class="text-center">
              <trash-2-icon
                v-b-tooltip.hover.v-secondary
                title="Xoá"
                size="16"
                class="custom-class cursor-pointer"
                @click="deleteFile(props.props.index)"
              />
            </div>
          </template>
        </good-table>
      </b-form-group>
    </div>
    <b-form-group
      id="input-group-1"
      label="Lý do gia hạn"
      label-for="input-1"
      class="grap-6"
    >
      <b-form-textarea
        v-model="data.lyDoGiaHan"
        placeholder="Nhập lý do gia hạn"
        rows="3"
        max-rows="6"
        :disabled="type == 'DUYET_GIA_HAN' || type == 'TU_CHOI_GIA_HAN'"
      />
    </b-form-group>
  </div>
</template>
<script>
import {
  BFormInput,
  BFormGroup,
  VBTooltip,
  BButton,
  BSpinner,
  BFormTextarea,
} from 'bootstrap-vue'
import { extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import 'vue-good-table/dist/vue-good-table.css'
import { Trash2Icon } from 'vue-feather-icons'
import END_POINTS from '@/api/endpoints'
import { removeDiacritical } from '@/utils/index'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import GoodTable from '@/components/GoodTable.vue'

extend('required', {
  ...required,
  message: 'Vui lòng nhập thông tin',
})

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BFormInput,
    BFormGroup,
    BButton,
    Trash2Icon,
    BSpinner,
    DatePicker,
    BFormTextarea,
    GoodTable,
  },
  props: {
    dataForm: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      errorFileSize: null,
      isLoadedFile: true,
      data: null,
      hoSoId: null,
      columns: [
        {
          label: 'STT',
          thClass: 'text-center custom-style',
          tdClass: 'text-center vertical-align-middle',
          width: '50px',
          field: 'STT',
          sortable: false,
        },
        {
          label: 'Tên file đính kèm',
          field: 'tenFile',
          thClass: 'text-center',
          tdClass: 'vertical-align-middle',
        },
        {
          label: 'Thao tác',
          field: 'actions',
          width: '100px',
          thClass: 'text-center',
          tdClass: 'text-center vertical-align-middle',
        },
      ],
    }
  },
  computed: {
    getColumns() {
      return ['DUYET_GIA_HAN', 'TU_CHOI_GIA_HAN'].includes(this.type) ? this.columns.filter(item => item.field !== 'actions') : this.columns
    },
  },
  created() {
    this.data = this.dataForm
    this.hoSoId = this.$route.query.id
  },
  methods: {
    normalizer(node) {
      return {
        id: node.donViId,
        label: node.tenDonVi,
        label2: removeDiacritical(node.tenDonVi),
      }
    },
    changeFile(e) {
      this.isLoadedFile = true
      const file = Array.from(e.target.files || e.dataTransfer.files)
      const numFile = 20 - this.data.dsFileDinhKem.length
      if (file.length > numFile) {
        this.$toast.error('Tệp đính kèm không vượt quá 20!')
      }
      // eslint-disable-next-line prefer-const
      let fileName = []
      // eslint-disable-next-line prefer-const
      let fileNameSize = []
      // eslint-disable-next-line prefer-const
      let fileResult = []
      // eslint-disable-next-line prefer-const
      let filesAttck = []
      // eslint-disable-next-line prefer-const
      let filesNot = []
      try {
        file.forEach(item => {
          if (!this.data.dsFileDinhKem.find(ele => ele.tenFile.indexOf(item.name) >= 0) && item.size < 26214400) {
            if (['application/msword', 'application/vnd.ms-excel', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/x-rar-compressed', 'image/png', 'image/jpg', 'image/gif', 'image/bmp'].includes(item.type)) {
              fileResult.push(item)
            } else {
              filesNot.push(item.name)
            }
          } else if (item.size > 26214400) {
            fileNameSize.push(item.name)
          } else {
            fileName.push(item.name)
          }
        })
        fileResult = fileResult.filter((item, idx) => idx < numFile)
        filesAttck.push(...fileResult)
        if (fileName.length > 0) {
        // eslint-disable-next-line no-unused-vars
          let fileNameError = ''
          fileName.forEach(item => {
            fileNameError += `- ${item}\n`
          })
          this.$toast.error(`Tệp đính kèm bị trùng: \n ${fileNameError}`)
        }
        if (fileNameSize.length > 0) {
        // eslint-disable-next-line no-unused-vars
          let fileNameSizeError = ''
          fileNameSize.forEach(item => {
            fileNameSizeError += `- ${item}\n`
          })
          this.$toast.error(`Tệp đính kèm:\n ${fileNameSizeError} dung lượng lớn hơn 25 MB`)
        }
        if (filesNot.length > 0) {
        // eslint-disable-next-line no-unused-vars
          let filesNotError = ''
          filesNot.forEach(item => {
            filesNotError += `- ${item}\n`
          })
          this.$toast.error(`Tệp đính kèm:\n ${filesNotError} không đúng định dạng`)
        }
        if (filesAttck.length > 0) {
          // eslint-disable-next-line prefer-const
          let formData = new FormData()
          filesAttck.forEach(item => {
            formData.append('listFileData', item)
          })
          this.$axios.post(END_POINTS.UPLOAD_FILE.MULTI, formData, false).then(res => {
            if (res.data) {
              const data = res.data.map(item => ({
                tenFile: item.fileName,
                path: item.objectName,
              }))
              this.data.dsFileDinhKem.push(...data)
            }
            this.isLoadedFile = true
          })
        }
      } finally {
        if (filesAttck.length < 0) {
          this.isLoadedFile = true
        }
      }
    },
    deleteFile(index) {
      this.data.dsFileDinhKem = this.data.dsFileDinhKem.filter((item, idx) => idx !== index)
    },
    getDataBoSung() {
      return this.data
    },
    changeSoNgayGiaHan() {
      if (this.data.soNgayGiaHan) {
        const payload = {
          hoSoId: this.hoSoId,
          soNgayGiaHan: this.data.soNgayGiaHan,
        }
        this.$axios.post(END_POINTS.TIEP_NHAN_HO_SO_TRUC_TIEP.TINH_NGAY_TRA_KG, payload, false).then(res => {
          if (res.data?.succeeded) {
            this.data.ngayHenTraMoi = new Date(res.data.data)
          }
        })
      } else {
        this.data.ngayHenTraMoi = null
      }
    },
    downloadFile(data) {
      this.$axios.post(END_POINTS.UPLOAD_FILE.DOWNLOAD, { path: data.path }).then(res => {
        if (res.data?.succeeded) {
          // eslint-disable-next-line prefer-const
          let a = document.createElement('a')
          a.href = `data:application/octet-stream;base64,${res.data.fileData}`
          a.download = res.data.fileName
          a.setAttribute('target', '_blank')
          a.click()
        }
      })
    },
  },
}
</script>
