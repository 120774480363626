<template>
  <div class="tab-common">
    <input
      id="tab1"
      v-model="componentName"
      class="tab-input"
      checked="checked"
      type="radio"
      name="tab-common"
      value="chi-tiet-ho-so"
    >
    <input
      id="tab2"
      v-model="componentName"
      class="tab-input"
      type="radio"
      name="tab-common"
      value="xu-ly-ho-so"
    >
    <input
      id="tab3"
      v-model="componentName"
      class="tab-input"
      type="radio"
      name="tab-common"
      value="tien-trinh-xu-ly"
    >
    <input
      id="tab4"
      v-model="componentName"
      class="tab-input"
      type="radio"
      name="tab-common"
      value="thong-tin-to-khai"
    >
    <nav>
      <ul class="tab-ul">
        <li
          v-if="['tra-cuu-ho-so'].includes($route.query.name) || checkQuyenThongTinHoSo"
          class="tab1"
        >
          <label
            class="tab-label"
            for="tab1"
          >THÔNG TIN HỒ SƠ</label>
        </li>
        <li
          v-if="checkShowXuLyHoSo()"
          :class="(['tra-cuu-ho-so','can-thu-phi-le-phi','cho-thu-phi-le-phi'].includes($route.query.name)) ? 'check-none' : ''"
          class="tab2"
        >
          <label
            class="tab-label"
            for="tab2"
          >XỬ LÝ HỒ SƠ</label>
        </li>
        <li
          v-if="['tra-cuu-ho-so','hs-giai-quyet'].includes($route.query.name) || checkQuyenTienTrinhXuLy"
          class="tab3"
        >
          <label
            class="tab-label"
            for="tab3"
          >TIẾN TRÌNH XỬ LÝ</label>
        </li>
        <li class="tab4">
          <label
            class="tab-label"
            for="tab4"
          >THÔNG TIN TỜ KHAI</label>
        </li>
      </ul>
    </nav>
    <section class="tab-section">
      <div class="tab">
        <KeepAlive>
          <component
            :is="componentName"
            :thong-tin-ho-so="thongTinHoSo"
            @close-page="closePage"
          />
        </KeepAlive>
      </div>
    </section>
    <common-modal
      ref="commomnModal"
      :title="title"
      :size="size"
      @handle-ok="handleOk"
    >
      <component
        :is="componentNameModal"
        :data-form="dataForm"
      />
    </common-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BCard,
  BFormTextarea,
  BFormGroup,
  VBTooltip,
} from 'bootstrap-vue'
// eslint-disable-next-line import/named
import {
  FilterIcon,
  Edit2Icon,
  EyeIcon,
  XCircleIcon,
} from 'vue-feather-icons'
import commonModal from '@/modules/tiep-nhan-ho-so/components/modal/CommonModal.vue'
import addCssSemantic from '@/mixins/mixins'
import ChiTietHoSo from '@/components/ChiTietHoSo.vue'
import TienTrinhXuLy from '@/components/TienTrinhXuLy.vue'
import XuLyHoSo from '@/components/XuLyHoSo.vue'
import ThongTinToKhai from '@/components/ThongTinToKhai.vue'
import { PERMISSION_NAME } from '@/constants/constants'
import { hasPermission } from '@/utils/permission-utils'
import END_POINTS from '@/api/endpoints'

export default {
  name: 'DanhSachHoSo',
  components: {
    FilterIcon,
    Edit2Icon,
    EyeIcon,
    XCircleIcon,
    BFormGroup,
    BFormTextarea,
    commonModal,
    ChiTietHoSo,
    TienTrinhXuLy,
    XuLyHoSo,
    BCard,
    ThongTinToKhai,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [addCssSemantic],
  data() {
    return {
      PERMISSION_NAME,
      componentName: 'chi-tiet-ho-so',
      contentModal: '',
      checkShowCanXuLy: true,
      checkShowChoXuLy: true,
      checkNgayCoKq: '',
      checkLePhi: 0,
      checkPhi: 0,
      checkTrangThai: true,
      componentNameModal: '',
      dataForm: null,
      isLoading: true,
      selectedItems: null,
      selectedNumber: 0,
      hoSoId: null,
      title: '',
      size: '',
      permissionName: null,
      permissionType: [
        {
          name: 'HSTNTRUC_TIEP',
          route: 'tiep-nhan-ho-so-truc-tiep',
        },
        {
          name: 'HSTNTRUC_TUYEN',
          route: 'tiep-nhan-ho-so-truc-tuyen',
        },
        {
          name: 'TIEP_NHAN_BO_SUNG',
          route: 'tiep-nhan-ho-so-bo-sung',
        },
        {
          name: 'BAN_GIAO_HO_SO',
          route: 'ban-giao-hs',
        },
        {
          name: 'HO_SO_DANG_XU_LY',
          route: 'ho-so-dang-xu-ly',
        },
        {
          name: 'YEU_CAU_BO_SUNG',
          route: 'yeu-cau-bs',
        },
        {
          name: 'PHAN_XU_LY_HO_SO',
          route: 'phan-xu-ly-ho-so',
        },
        {
          name: 'DUYET_DE_XUAT',
          route: 'duyet-de-xuat',
        },
        {
          name: 'DUYET_KY_HO_SO',
          route: 'duyet-ky-ho-so',
        },
        {
          name: 'DUYET_GIA_HAN_HO_SO',
          route: 'duyet-gia-han',
        },
        {
          name: 'HO_SO_CHO_THU_PHI',
          route: 'cho-thu-phi-le-phi',
        },
        {
          name: 'HO_SO_CAN_THU_PHI',
          route: 'can-thu-phi-le-phi',
        },
        {
          name: 'HO_SO_GIAI_QUYET',
          route: 'hs-giai-quyet',
        },
        {
          name: 'HO_SO_TU_CHOI_GIAI_QUYET',
          route: 'hs-khong-giai-quyet',
        },
        {
          name: 'NHAN_KET_QUA_HO_SO',
          route: 'nhan-ket-qua',
        },
        {
          name: 'TRA_CUU',
          route: 'tra-cuu-ho-so',
        },
      ],
      thongTinHoSo: {},
    }
  },
  computed: {
    ...mapGetters({
      permission: 'common/permissions',
    }),
    checkQuyenThongTinHoSo() {
      return hasPermission([PERMISSION_NAME[this.permissionName].CHI_TIET_THONG_TIN_CHUNG])
    },
    checkQuyenXuLyHoSo() {
      return hasPermission([PERMISSION_NAME[this.permissionName].CHI_TIET_XU_LY_HO_SO])
    },
    checkQuyenTienTrinhXuLy() {
      return hasPermission([PERMISSION_NAME[this.permissionName].CHI_TIET_TIEN_TRINH_XU_LY])
    },
    checkQuyenSua() {
      return hasPermission([PERMISSION_NAME[this.permissionName].SUA])
    },
    checkQuyenXoa() {
      return hasPermission([PERMISSION_NAME[this.permissionName].XOA])
    },
  },
  watch: {
    '$route.query.id': {
      handler(newData) {
        if (newData) {
          this.$axios.get(`${END_POINTS.TIEP_NHAN_HO_SO_TRUC_TIEP.CHI_TIET}?id=${newData}`).then(res => {
            if (res) {
              this.thongTinHoSo = res.data.data
              this.isLoading = false
              this.checkNgayCoKq = res.data.data.ngayCoKetQua
              this.checkShowChoXuLy = res.data.data.isChoThuPhi
              this.checkShowCanXuLy = res.data.data.isCanThuPhi
              this.checkTrangThai = res.data.data.isDaThuPhi
              this.checkPhi = res.data.data.phiHs
              this.checkLePhi = res.data.data.lePhi
            }
          })
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.hoSoId = this.$route.query.id
    const result = this.permissionType.find(item => item.route === this.$route.query.name)
    this.permissionName = result ? result.name : null
    // this.getDataMauHoSo()
    if (['tra-cuu-ho-so'].includes(this.$route.query.name)) {
      // eslint-disable-next-line no-nested-ternary
      this.componentName = 'chi-tiet-ho-so'
    } else {
      // eslint-disable-next-line no-nested-ternary
      this.componentName = this.checkQuyenThongTinHoSo ? 'chi-tiet-ho-so' : this.checkQuyenXuLyHoSo ? 'xu-ly-ho-so' ? this.checkQuyenTienTrinhXuLy : 'tien-trinh-xu-ly' : 'thong-tin-to-khai'
    }
  },

  methods: {
    handleOk() {

    },
    closePage() {
      if (this.$route.query.name) {
        this.$router.push({ name: this.$route.query.name })
      } else {
        this.$router.push({ path: '/' })
      }
    },
    checkShowXuLyHoSo() {
      if (this.checkQuyenXuLyHoSo && !this.isLoading) {
        if (this.checkNgayCoKq) {
          return !(this.checkShowCanXuLy || this.checkShowChoXuLy)
        }
        return true
      }
      return false
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  .card .card-header {
    border-bottom: 1.5px solid #EEEEEE !important;
    display: inline-block;
    padding: 0.5rem 1.5rem !important;
  }
  .cs-table {
    min-height: 150px;
    .actions-col-width {
      max-width: 50px;
    }
  }
  .font-weight-bold-700 {
    font-weight: 700;
    color: #333333;
  }
  .check-none{
    display: none !important;
  }
</style>
