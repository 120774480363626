<template>
  <div>
    <div>
      <table style="width: 100%;">
        <tr>
          <td style="text-align: center; width: 40%; text-transform: uppercase; font-weight: bold;">
            Bộ phận tiếp nhận và
            <div style="text-decoration: underline; text-decoration: underline;">
              Trả kết quả
            </div>
          </td>
          <td style="text-align: center; width: 60%;">
            <div style="text-transform: uppercase; font-weight: bold;">
              Cộng hòa xã hội chủ nghĩa Việt Nam
            </div>
            <div style="display: block; text-decoration: underline; font-weight: bold; margin-bottom: 3px;">
              Độc lập - Tự do - Hạnh phúc
            </div>
            <span style="font-style: italic;">ngày {{ dayNow[0] }} tháng {{ dayNow[1] }} năm {{ dayNow[2] }}</span>
          </td>
        </tr>
      </table>
      <br><br>
      <div style="text-align: center; margin-bottom: 1rem;">
        <div style="text-transform: uppercase; font-weight: bold;">
          Giấy giao - nhận hồ sơ
        </div>
        <div style="font-style: italic; display: block;">
          (Liên 1: Lưu tại bộ phận tiếp nhận và trả kết quả)
        </div>
      </div>
      <div style="margin-bottom: 1rem;">
        <b>Tiếp nhận hồ sơ của:</b> {{ tableData.chuHoSo_HoVaTen }}
      </div>
      <div style="margin-bottom: 1rem;">
        <b>Địa chỉ:</b> {{ tableData.chuHoSo_DiaChiDayDu }}
      </div>
      <div style="margin-bottom: 1rem;">
        <b>Số điện thoại:</b> {{ tableData.chuHoSo_SoDienThoai }}
      </div>
      <div style="margin-bottom: 1rem;">
        <b>Nội dung yêu cầu giải quyết:</b> {{ tableData.tenThuTucHanhChinh }}
      </div>
      <div style="margin-bottom: 1rem;">
        <b>Số văn bản:</b> {{ tableData.code }}
      </div>
      <div style="margin-bottom: 1rem;">
        <b>Bộ phận bàn giao:</b> {{ tableData.tenBoPhanBanGiao || '' }}
      </div>
      <div style="margin-bottom: 1rem;">
        <b>Cán bộ bàn giao:</b> {{ tableData.tenCanBoBanGiao }}
      </div>
      <div style="margin-bottom: 1rem;">
        <b>Bộ phận tiếp nhận:</b> {{ tableData.tenBoPhanTiepNhan }}
      </div>
      <div style="margin-bottom: 1rem;">
        <b>Cán bộ tiếp nhận:</b> {{ tableData.tenCanBoTiepNhan }}
      </div>
      <div style="margin-bottom: 1rem;">
        <b>Ngày, giờ bàn giao:</b> {{ tableData.ngayBanGiao ? formatDateTime(tableData.ngayBanGiao) : '' }}
      </div>
      <div style="margin-bottom: 1rem;">
        <b>Thành phần hồ sơ</b><span v-if="!(tableData.hoSoGiayToModels && tableData.hoSoGiayToModels.length > 0)">: Không có</span>
      </div>
      <div
        v-if="tableData.hoSoGiayToModels && tableData.hoSoGiayToModels.length > 0"
        style="margin-bottom: 1rem;"
      >
        <table style="width: 100%; border-collapse: collapse; border: 1px solid black;">
          <tr>
            <th style="border: 1px solid black; padding: 10px; width: 35px !important; text-align: center;">
              STT
            </th>
            <th style="border: 1px solid black; padding: 10px; text-align: center;">
              Tên giấy tờ
            </th>
            <th style="border: 1px solid black; padding: 10px; width: 100px !important; text-align: center;">
              Bản chính
            </th>
            <th style="border: 1px solid black; padding: 10px; width: 100px !important; text-align: center;">
              Bản sao
            </th>
            <th style="border: 1px solid black; padding: 10px; width: 100px !important; text-align: center;">
              Bản photo
            </th>
          </tr>
          <tr
            v-for="(item, index) in tableData.hoSoGiayToModels"
            :key="index"
          >
            <td style="border: 1px solid black; padding: 10px; text-align: center;">
              {{ index + 1 }}
            </td>
            <td style="border: 1px solid black; padding: 10px;">
              {{ item.tenGiayTo }}
            </td>
            <td style="border: 1px solid black; padding: 10px; text-align: center;">
              {{ item.banChinh }}
            </td>
            <td style="border: 1px solid black; padding: 10px; text-align: center;">
              {{ item.banSao }}
            </td>
            <td style="border: 1px solid black; padding: 10px; text-align: center;">
              {{ item.banPhoto }}
            </td>
          </tr>
        </table>
      </div>
      <div style="margin-bottom: 1rem;">
        <b>Giấy tờ khác</b><span v-if="!(tableData.hoSoGiayToKhacModels && tableData.hoSoGiayToKhacModels.length > 0)">: Không có</span>
      </div>
      <div v-if="tableData.hoSoGiayToKhacModels && tableData.hoSoGiayToKhacModels.length > 0">
        <table style="width: 100%; border-collapse: collapse; border: 1px solid black;">
          <tr>
            <th style="border: 1px solid black; padding: 10px; width: 35px !important; text-align: center;">
              STT
            </th>
            <th style="border: 1px solid black; padding: 10px; text-align: center;">
              Tên giấy tờ
            </th>
          </tr>
          <tr
            v-for="(item, index) in tableData.hoSoGiayToKhacModels"
            :key="index"
          >
            <td style="border: 1px solid black; padding: 10px; text-align: center;">
              {{ index + 1 }}
            </td>
            <td style="border: 1px solid black; padding: 10px;;">
              {{ item.tenGiayTo }}
            </td>
          </tr>
        </table>
      </div>
      <br><br><br><br>
      <table style="width: 100%;">
        <tr>
          <td style="text-align: center; width: 50%;">
            <div style="text-transform: uppercase; font-weight: bold;">
              Cán bộ bàn giao
            </div>
            <div style="display: block; font-style: italic;">
              (Ký và ghi rõ họ tên)
            </div>
            <br><br><br><br>
            {{ tableData.tenCanBoBanGiao }}
          </td>
          <td style="text-align: center; width: 50%;">
            <div style="text-transform: uppercase; font-weight: bold;">
              Cán bộ tiếp nhận
            </div>
            <div style="display: block; font-style: italic;">
              (Ký và ghi rõ họ tên)
            </div>
            <br><br><br><br>
            <span>{{ tableData.tenCanBoTiepNhan }}</span>
          </td>
        </tr>
      </table>
    </div>
    <div style="page-break-after: always;" />
    <div>&nbsp;</div>
    <br
      clear="all"
      style="mso-special-character:line-break;page-break-before:always"
    >
    <div>
      <table style="width: 100%;">
        <tr>
          <td style="text-align: center; width: 40%; text-transform: uppercase; font-weight: bold;">
            Bộ phận tiếp nhận và
            <div style="text-decoration: underline; text-decoration: underline;">
              Trả kết quả
            </div>
          </td>
          <td style="text-align: center; width: 60%;">
            <div style="text-transform: uppercase; font-weight: bold;">
              Cộng hòa xã hội chủ nghĩa Việt Nam
            </div>
            <div style="display: block; text-decoration: underline; font-weight: bold; margin-bottom: 3px;">
              Độc lập - Tự do - Hạnh phúc
            </div>
            <span style="font-style: italic;">ngày {{ dayNow[0] }} tháng {{ dayNow[1] }} năm {{ dayNow[2] }}</span>
          </td>
        </tr>
      </table>
      <br><br>
      <div style="text-align: center; margin-bottom: 1rem;">
        <div style="text-transform: uppercase; font-weight: bold;">
          Giấy giao - nhận hồ sơ
        </div>
        <div style="font-style: italic; display: block;">
          (Liên 2: Giao cho bộ phận tiếp nhận bàn giao)
        </div>
      </div>
      <div style="margin-bottom: 1rem;">
        <b>Tiếp nhận hồ sơ của:</b> {{ tableData.chuHoSo_HoVaTen }}
      </div>
      <div style="margin-bottom: 1rem;">
        <b>Địa chỉ:</b> {{ tableData.chuHoSo_DiaChiDayDu }}
      </div>
      <div style="margin-bottom: 1rem;">
        <b>Số điện thoại:</b> {{ tableData.chuHoSo_SoDienThoai }}
      </div>
      <div style="margin-bottom: 1rem;">
        <b>Nội dung yêu cầu giải quyết:</b> {{ tableData.tenThuTucHanhChinh }}
      </div>
      <div style="margin-bottom: 1rem;">
        <b>Số văn bản:</b> {{ tableData.code }}
      </div>
      <div style="margin-bottom: 1rem;">
        <b>Bộ phận bàn giao:</b> {{ tableData.tenBoPhanBanGiao || '' }}
      </div>
      <div style="margin-bottom: 1rem;">
        <b>Cán bộ bàn giao:</b> {{ tableData.tenCanBoBanGiao }}
      </div>
      <div style="margin-bottom: 1rem;">
        <b>Bộ phận tiếp nhận:</b> {{ tableData.tenBoPhanTiepNhan }}
      </div>
      <div style="margin-bottom: 1rem;">
        <b>Cán bộ tiếp nhận:</b> {{ tableData.tenCanBoTiepNhan }}
      </div>
      <div style="margin-bottom: 1rem;">
        <b>Ngày, giờ bàn giao:</b> {{ tableData.ngayBanGiao ? formatDateTime(tableData.ngayBanGiao) : '' }}
      </div>
      <div :style="!(tableData.hoSoGiayToModels && tableData.hoSoGiayToModels.length > 0) ? 'margin-bottom: 1rem;' : ''">
        <b>Thành phần hồ sơ</b><span v-if="!(tableData.hoSoGiayToModels && tableData.hoSoGiayToModels.length > 0)">: Không có</span>
      </div>
      <div
        v-if="tableData.hoSoGiayToModels && tableData.hoSoGiayToModels.length > 0"
        style="margin-bottom: 1rem;"
      >
        <table style="width: 100%; border-collapse: collapse; border: 1px solid black;">
          <tr>
            <th style="border: 1px solid black; padding: 10px; width: 35px !important; text-align: center;">
              STT
            </th>
            <th style="border: 1px solid black; padding: 10px; text-align: center;">
              Tên giấy tờ
            </th>
            <th style="border: 1px solid black; padding: 10px; width: 100px !important; text-align: center;">
              Bản chính
            </th>
            <th style="border: 1px solid black; padding: 10px; width: 100px !important; text-align: center;">
              Bản sao
            </th>
            <th style="border: 1px solid black; padding: 10px; width: 100px !important; text-align: center;">
              Bản photo
            </th>
          </tr>
          <tr
            v-for="(item, index) in tableData.hoSoGiayToModels"
            :key="index"
          >
            <td style="border: 1px solid black; padding: 10px; text-align: center;">
              {{ index + 1 }}
            </td>
            <td style="border: 1px solid black; padding: 10px;">
              {{ item.tenGiayTo }}
            </td>
            <td style="border: 1px solid black; padding: 10px; text-align: center;">
              {{ item.banChinh }}
            </td>
            <td style="border: 1px solid black; padding: 10px; text-align: center;">
              {{ item.banSao }}
            </td>
            <td style="border: 1px solid black; padding: 10px; text-align: center;">
              {{ item.banPhoto }}
            </td>
          </tr>
        </table>
      </div>
      <div :style="!(tableData.hoSoGiayToKhacModels && tableData.hoSoGiayToKhacModels.length > 0) ? 'margin-bottom: 1rem;' : ''">
        <b>Giấy tờ khác</b><span v-if="!(tableData.hoSoGiayToKhacModels && tableData.hoSoGiayToKhacModels.length > 0)">: Không có</span>
      </div>
      <div
        v-if="tableData.hoSoGiayToKhacModels && tableData.hoSoGiayToKhacModels.length > 0"
        style="margin-bottom: 1rem;"
      >
        <table style="width: 100%; border-collapse: collapse; border: 1px solid black;">
          <tr>
            <th style="border: 1px solid black; padding: 10px; width: 35px !important; text-align: center;">
              STT
            </th>
            <th style="border: 1px solid black; padding: 10px; text-align: center;">
              Tên giấy tờ
            </th>
          </tr>
          <tr
            v-for="(item, index) in tableData.hoSoGiayToKhacModels"
            :key="index"
          >
            <td style="border: 1px solid black; padding: 10px; text-align: center;">
              {{ index + 1 }}
            </td>
            <td style="border: 1px solid black; padding: 10px;;">
              {{ item.tenGiayTo }}
            </td>
          </tr>
        </table>
      </div>
      <br><br><br><br>
      <table style="width: 100%;">
        <tr>
          <td style="text-align: center; width: 50%;">
            <div style="text-transform: uppercase; font-weight: bold;">
              Cán bộ bàn giao
            </div>
            <div style="display: block; font-style: italic;">
              (Ký và ghi rõ họ tên)
            </div>
            <br><br><br><br>
            {{ tableData.tenCanBoBanGiao }}
          </td>
          <td style="text-align: center; width: 50%;">
            <div style="text-transform: uppercase; font-weight: bold;">
              Cán bộ tiếp nhận
            </div>
            <div style="display: block; font-style: italic;">
              (Ký và ghi rõ họ tên)
            </div>
            <br><br><br><br>
            <span>{{ tableData.tenCanBoTiepNhan }}</span>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import * as dayjs from 'dayjs'

export default {
  components: {
  },
  props: {
    tableData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      dayNow: new Date(),
    }
  },
  created() {
    this.dayNow = dayjs(this.tableData.ngayNop).format('DD/MM/YYYY').split('/')
  },
  methods: {
    formatDate(date) {
      return date ? dayjs(date).format('HH:mm - DD/MM/YYYY') : null
    },
    formatDateTime(date) {
      let newDate = new Date()
      if (date) {
        newDate = new Date(date)
      }
      return `${newDate.getHours()} giờ ${newDate.getMinutes()} phút, ngày ${newDate.getDate()} tháng ${newDate.getMonth() + 1} năm ${newDate.getFullYear()}`
    },
  },
}
</script>
