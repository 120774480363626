<template>
  <b-card
    no-body
    class="mb-0"
  >
    <div class="card-body">
      <validation-observer
        ref="commonRules"
      >
        <div class="custom-grid-container">
          <b-form-group
            id="input-group-1"
            label="Xử lý"
            label-for="input-1"
            class="required"
            :class="!(type === 'TRA_KET_QUA') ? 'grap-2' : 'grap-12'"
          >
            <validation-provider
              v-slot="{ errors }"
              key="xuLy"
              :rules="{ required: true }"
              name="Xử lý"
            >
              <treeselect
                id="linhVuc"
                ref="tenXuLy"
                v-model="data.command"
                v-format-v-select
                :default-options="xlCbx"
                placeholder="Chọn xử lý"
                :limit="0"
                :limit-text="(count) => ` + ${count} lựa chọn`"
                no-options-text="Không có dữ liệu"
                no-results-text="Không có dữ liệu"
                loading-text="Đang tìm kiếm"
                :normalizer="normalizerCommand"
                :match-keys="['label', 'label2']"
                :load-options="onChange"
                :async="true"
                :clear-on-select="true"
                @input="changeXuLy()"
                @select="$refs.tenXuLy.resetSearchQuery()"
              >
                <label
                  slot="option-label"
                  slot-scope="{ node, shouldShowCount, count, labelClassName }"
                  :class="labelClassName"
                  :title="node.label"
                >
                  {{ node.label }}
                </label>
              </treeselect>
              <span class="label-noti-validate">{{ errors[0] }}</span>
            </validation-provider>
          </b-form-group>
          <b-form-group
            v-if="!(type === 'TRA_KET_QUA')"
            id="input-group-1"
            label="Người nhận xử lý"
            label-for="input-1"
            class="required grap-2"
          >
            <validation-provider
              v-slot="{ errors }"
              key="nguoiNhanXuLy"
              :rules="{ required: true }"
              name="Người nhận xử lý"
            >
              <treeselect
                v-model="data.allowIdentityId"
                v-format-v-select
                :options="nxlCbx"
                placeholder="Chọn người nhận xử lý"
                :normalizer="normalizer"
                no-results-text="Không có dữ liệu"
                no-options-text="Không có dữ liệu"
                :disabled="data.command ? false : true"
              />
              <span class="label-noti-validate">{{ errors[0] }}</span>
            </validation-provider>
          </b-form-group>
          <b-form-group
            id="input-group-1"
            label="Ý kiến xử lý"
            label-for="input-1"
            class="grap-12"
          >
            <ckeditor
              v-model="data.comment"
              :editor="editor"
              :config="editorConfig"
            />
          </b-form-group>
        </div>
        <div class="d-flex justify-content-between">
          <div>Tệp đính kèm</div>
          <div>
            <b-button
              variant="primary"
              class="d-flex"
              :disabled="!isLoadedFile"
              size="sm"
              @click="(e) => $refs.fileInput.click()"
            >
              <b-spinner
                v-if="!isLoadedFile"
                small
                label="Spinning"
                style="margin-right: 5px;"
              />
              Tải file
            </b-button>
            <input
              ref="fileInput"
              type="file"
              class="d-none"
              multiple
              accept="application/msword, application/vnd.ms-excel, application/pdf, .docx, .xlsx, application/x-rar-compressed, image/*"
              @change="changeFile"
            >
          </div>
        </div>
        <good-table
          ref="hoSo"
          class="mt-1"
          :pagination="false"
          :selectable="false"
          :columns="columns"
          :rows="data.tepDinhKem"
        >
          <template
            slot="custom-row"
            slot-scope="props"
          >
            <span v-if="props.props.column.field == 'tenFile'">
              <a
                href="javascript:;"
                @click="downloadFile(props.props.row)"
              >{{ `${props.props.row.tenFile}` }}</a>
            </span>
          </template>
          <template
            slot="actions"
            slot-scope="props"
          >
            <div class="text-center">
              <trash-2-icon
                v-b-tooltip.hover.v-secondary
                title="Xoá"
                size="16"
                class="custom-class cursor-pointer"
                @click="deleteFile(props.props.index)"
              />
            </div>
          </template>
        </good-table>
        <div>
          <component
            :is="componentForm"
            ref="componentForm"
            :data-form="dataFormComponent"
            :type="type"
          />
        </div>
      </validation-observer>
      <div class="d-sm-flex justify-content-end mt-1">
        <button
          v-if="xacThucKySo"
          class="compact ui btn-primary button mr-1"
          @click="onXacNhanKySo"
        >
          Xác nhận
        </button>
        <button
          v-if="!xacThucKySo"
          class="compact ui btn-primary button mr-1"
          @click="onSubmit"
        >
          Đồng ý
        </button>
        <button
          class="compact ui btn-danger button"
          @click="closePage"
        >
          Đóng
        </button>
      </div>
    </div>
    <commonModal
      ref="commomnSubModal"
      :title="title"
      :hide-header="hideHeader"
      @reset-form="cancelForm"
    >
      <component
        :is="componentName"
        id="componentPrint"
        ref="componentPrint"
        :table-data="tableData"
      />
      <div
        v-if="isShowFooter"
        slot="footer"
      >
        <div class="w-100">
          <b-button
            variant="danger"
            size="sm"
            class="float-right"
            @click="hideModal"
          >
            Đóng
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            class="float-right mr-1"
            @click="onPrintModal"
          >
            In phiếu bàn giao
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            class="float-right mr-1"
            @click="onExportModal"
          >
            Xuất ra file
          </b-button>
        </div>
      </div>
    </commonModal>
    <iframe
      id="printf"
      name="printf"
      class="d-none"
    />
  </b-card>
</template>
<script>
import END_POINTS from '@/api/endpoints'
import {
  BCard,
  BButton,
  BSpinner,
  BFormGroup,
  VBTooltip,
} from 'bootstrap-vue'
import addCssSemantic from '@/mixins/mixins'
import { quillEditor } from 'vue-quill-editor'
import '@/@core/scss/vue/libs/quill.scss'
import { Trash2Icon } from 'vue-feather-icons'
import { extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import commonModal from '@/modules/xu-ly-ho-so/components/modal/CommonModal.vue'
import InPhieuBanGiaoForm from '@/modules/xu-ly-ho-so/components/form/InPhieuBanGiaoForm.vue'
import ThongTinBoSung from '@/components/from/ThongTinBoSung.vue'
import ThongTinGiaHan from '@/components/from/ThongTinGiaHan.vue'
import ThemKetQuaFile from '@/components/from/ThemKetQuaFile.vue'
import GoodTable from '@/components/GoodTable.vue'
import _debounce from 'lodash/debounce'
import { removeDiacritical } from '@/utils/index'
import { vgca_sign_msg } from '@/utils/vgcaplugin'
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials'
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold'
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic'
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline'
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough'
import Subscript from '@ckeditor/ckeditor5-basic-styles/src/subscript'
import Superscript from '@ckeditor/ckeditor5-basic-styles/src/superscript'
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link'
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph'
import ImagePlugin from '@ckeditor/ckeditor5-image/src/image'
import ImageCaptionPlugin from '@ckeditor/ckeditor5-image/src/imagecaption'
import ImageStylePlugin from '@ckeditor/ckeditor5-image/src/imagestyle'
import ImageToolbarPlugin from '@ckeditor/ckeditor5-image/src/imagetoolbar'
import ImageUploadPlugin from '@ckeditor/ckeditor5-image/src/imageupload'
import AutoLink from '@ckeditor/ckeditor5-link/src/autolink'
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment'
import Table from '@ckeditor/ckeditor5-table/src/table'
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar'
import TableCaption from '@ckeditor/ckeditor5-table/src/tablecaption'
import ListStyle from '@ckeditor/ckeditor5-list/src/liststyle'
import HeadingPlugin from '@ckeditor/ckeditor5-heading/src/heading'
import Code from '@ckeditor/ckeditor5-basic-styles/src/code'
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties'
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties'
import PageBreak from '@ckeditor/ckeditor5-page-break/src/pagebreak'
import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter'
// import '@ckeditor/ckeditor5-build-classic/build/translations/vi'
import '@/utils/vi'

extend('required', {
  ...required,
  message: 'Vui lòng nhập thông tin',
})

export default {
  name: 'ChiTietHoSoCommon',
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BButton,
    BSpinner,
    BFormGroup,
    quillEditor,
    Trash2Icon,
    commonModal,
    InPhieuBanGiaoForm,
    ThongTinBoSung,
    ThongTinGiaHan,
    ThemKetQuaFile,
    GoodTable,
  },
  mixins: [addCssSemantic],
  props: {
    dataForm: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        language: 'vi',
        placeholder: 'Nhập ý kiến xử lý',
        plugins: [
          EssentialsPlugin,
          Base64UploadAdapter,
          LinkPlugin,
          BoldPlugin,
          ItalicPlugin,
          ParagraphPlugin,
          ImagePlugin,
          ImageCaptionPlugin,
          ImageStylePlugin,
          ImageToolbarPlugin,
          ImageUploadPlugin,
          AutoLink,
          Alignment,
          Table,
          TableToolbar,
          ListStyle,
          HeadingPlugin,
          Underline,
          Strikethrough,
          Subscript,
          Superscript,
          Code,
          TableToolbar,
          TableCaption,
          TableProperties,
          TableCellProperties,
          PageBreak,
        ],
        table: {
          contentToolbar: [
            'tableColumn',
            'tableRow',
            'mergeTableCells',
            'toggleTableCaption',
            'tableProperties',
            'tableCellProperties',
          ],
        },
        toolbar: {
          items: [
            'heading',
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'subscript',
            'superscript',
            'uploadImage',
            'link',
            'alignment',
            'insertTable',
            'bulletedList',
            'numberedList',
            'pageBreak',
            'code',
            'undo',
            'redo',
          ],
        },
      },
      isLoadedFile: true,
      signature: null,
      hoSoId: null,
      xlCbx: [],
      nxlCbx: [],
      tableData: null,
      hideHeader: true,
      title: '',
      componentName: 'InPhieuBanGiaoForm',
      isShowFooter: true,
      componentForm: null,
      dataFormComponent: null,
      data: {
        type: null,
        command: null,
        allowIdentityId: null,
        comment: null,
        tepDinhKem: [],
        xacThucKySo: null,
      },
      detail: null,
      type: null,
      xacThucKySo: null,
      columns: [
        {
          label: 'STT',
          thClass: 'text-center custom-style',
          tdClass: 'text-center vertical-align-middle',
          width: '50px',
          field: 'STT',
          sortable: false,
        },
        {
          label: 'Tên file đính kèm',
          field: 'tenFile',
          thClass: 'text-center',
          tdClass: 'vertical-align-middle',
        },
        {
          label: 'Thao tác',
          field: 'actions',
          width: '100px',
          thClass: 'text-center',
          tdClass: 'text-center vertical-align-middle',
        },
      ],
    }
  },
  created() {
    this.hoSoId = this.$route.query.id
    this.$axios.get(`${END_POINTS.TIEP_NHAN_HO_SO_TRUC_TIEP.CHI_TIET}?id=${this.hoSoId}`).then(res => {
      if (res.data?.succeeded) {
        this.detail = res.data.data
        this.getXuLy()
        this.data = {
          type: this.dataForm.type,
          command: this.dataForm.command,
          allowIdentityId: this.dataForm.allowIdentityId,
          comment: this.dataForm.comment,
          tepDinhKem: this.dataForm.tepDinhKem || [],
          xacThucKySo: this.dataForm.xacThucKySo,
        }
      }
    })
  },
  methods: {
    normalizerCommand(node) {
      return {
        id: node.commandName,
        label: node.commandName,
      }
    },
    onChange({ action, searchQuery, callback }) {
      if (action === 'ASYNC_SEARCH') {
        this.handleSearchChange(searchQuery, callback)
      }
    },
    // eslint-disable-next-line func-names
    handleSearchChange: _debounce(async function (query, callback) {
      const response = this.xlCbx.filter(item => removeDiacritical(item.commandName).indexOf(removeDiacritical(query)) >= 0)
      callback(null, response)
    }, 100),
    normalizer(node) {
      return {
        id: node.userId,
        label: node.fullName,
      }
    },
    onSubmit() {
      this.$refs.commonRules.validate().then(success => {
        if (success) {
          const xl = this.xlCbx.find(item => item.commandName === this.data.command)
          if (['YEU_CAU_BO_SUNG', 'TIEP_NHAN_BO_SUNG'].includes(xl.type)) {
            this.componentForm = 'ThongTinBoSung'
            this.data = {
              ...this.data,
              thongTinBoSung: {
                hoSoId: this.hoSoId,
                hoSo_GiayToBoSungModels: [
                  ...this.$refs.componentForm.getDataBoSung(),
                ],
              },
            }
          } else if (['TRINH_DUYET_GIA_HAN', 'DUYET_GIA_HAN', 'TU_CHOI_GIA_HAN'].includes(xl.type)) {
            this.componentForm = 'ThongTinGiaHan'
            this.data = {
              ...this.data,
              thongTinGiaHan: {
                ...this.$refs.componentForm.getDataBoSung(),
                hoSoId: this.hoSoId,
              },
            }
          } else if (['KY_DUYET_GIAI_QUYET_HO_SO', 'KY_DUYET_TU_CHOI_GIAI_QUYET_HO_SO', 'TRA_KET_QUA'].includes(xl.type)) {
            this.data = {
              ...this.data,
              tepKetQuaHoSo: [
                ...this.$refs.componentForm.getData(),
              ],
            }
          }
          this.data.type = xl ? xl.type : null
          this.data.xacThucKySo = xl ? xl.xacThucKySo : null
          if (this.signature) {
            this.data.signature = this.signature
          }
          this.$axios.post(`${END_POINTS.WORKFLOW_CUSTOM.THUC_THI}/${this.hoSoId}`, this.data, false).then(res => {
            if (res.data?.code === 200) {
              this.xlCbx = res.data.data
              if (xl.type === 'BAN_GIAO_HO_SO') {
                this.onPrint()
              } else {
                this.closePage()
              }
              this.$toast.success(res.data.message)
            } else {
              this.$toast.error(res.data.message)
            }
          })
        }
      })
    },
    onXacNhanKySo(sender = '_Sign') {
      const SignCallBack = async (sender1, rv) => {
        const received = JSON.parse(rv)
        if (received.Status === 0) {
          this.signature = received.Signature
          this.onSubmit()
        }
      }
      // eslint-disable-next-line global-require
      const crypto = require('crypto')
      // hash from file hashed joim text hashed
      const hash = crypto.createHash('sha256').update('1').digest('base64')
      const hashValue = hash
      const hashAlg = 'SHA256'
      const prms = {}
      prms.HashValue = hashValue
      prms.HashAlg = hashAlg
      const jsonPrms = JSON.stringify(prms)
      vgca_sign_msg(sender, jsonPrms, SignCallBack)
    },
    onPrint() {
      this.hideHeader = true
      this.title = ''
      this.componentName = 'InPhieuBanGiaoForm'
      this.isShowFooter = true
      this.$axios.get(`${END_POINTS.TIEP_NHAN_HO_SO_TRUC_TIEP.CHI_TIET}?id=${this.hoSoId}`).then(res => {
        if (res.data?.succeeded) {
          this.detail = res.data.data
          this.tableData = this.detail
          this.$refs.commomnSubModal.showModal()
        }
      })
    },
    onPrintModal() {
      const newWin = window.frames.printf
      // eslint-disable-next-line prefer-template
      newWin.document.write(`<style>
          @page {
            size: auto A4 landscape;
            margin: 3mm;
          }
        </style>${this.$refs.componentPrint.$el.innerHTML}`)
      newWin.document.close()
      newWin.focus()
      newWin.print()
    },
    onExportModal() {
      const preHtml = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>"
      const postHtml = '</body></html>'
      const html = preHtml + this.$refs.componentPrint.$el.innerHTML + postHtml
      const blob = new Blob(['\ufeff', html], {
        type: 'application/msword',
      })
      // Specify link url
      const url = `data:application/vnd.ms-word;charset=utf-8,${encodeURIComponent(html)}`
      // Specify file name
      const filename = 'giao-nhan-ho-so.doc'
      // Create download link element
      const downloadLink = document.createElement('a')
      document.body.appendChild(downloadLink)
      if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, filename)
      } else {
        // Create a link to the file
        downloadLink.href = url
        // Setting the file name
        downloadLink.download = filename
        // triggering the function
        downloadLink.click()
      }
      document.body.removeChild(downloadLink)
    },
    getXuLy() {
      this.$axios.get(`${END_POINTS.WORKFLOW_CUSTOM.DANH_SACH_XU_LY}/${this.hoSoId}`).then(res => {
        if (res.data?.code === 200) {
          this.xlCbx = res.data.data || []
          if (this.xlCbx.length === 1) {
            this.data.command = this.xlCbx[0].commandName
            this.type = this.xlCbx[0].type
            this.xacThucKySo = this.xlCbx[0].xacThucKySo
          }
        }
      })
    },
    getNguoiXuLy() {
      this.$axios.get(`${END_POINTS.WORKFLOW_CUSTOM.DANH_SACH_NGUOI_XU_LY}/${this.hoSoId}`, { command: this.data.command }).then(res => {
        if (res.data?.code === 200) {
          this.nxlCbx = res.data.data || []
          if (this.nxlCbx.length === 1) {
            this.data.allowIdentityId = this.nxlCbx[0].userId
          }
        }
      })
    },
    changeFile(e) {
      this.isLoadedFile = true
      const file = Array.from(e.target.files || e.dataTransfer.files)
      const numFile = 20 - this.data.tepDinhKem.length
      if (file.length > numFile) {
        this.$toast.error('Tệp đính kèm không vượt quá 20!')
      }
      // eslint-disable-next-line prefer-const
      let fileName = []
      // eslint-disable-next-line prefer-const
      let fileNameSize = []
      // eslint-disable-next-line prefer-const
      let fileResult = []
      // eslint-disable-next-line prefer-const
      let filesAttck = []
      // eslint-disable-next-line prefer-const
      let filesNot = []
      try {
        file.forEach(item => {
          if (!this.data.tepDinhKem.find(ele => ele.tenFile.indexOf(item.name) >= 0) && item.size < 26214400) {
            if (['application/msword', 'application/vnd.ms-excel', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/x-rar-compressed', 'image/png', 'image/jpg', 'image/gif', 'image/bmp'].includes(item.type)) {
              fileResult.push(item)
            } else {
              filesNot.push(item.name)
            }
          } else if (item.size > 26214400) {
            fileNameSize.push(item.name)
          } else {
            fileName.push(item.name)
          }
        })
        fileResult = fileResult.filter((item, index) => index < numFile)
        filesAttck.push(...fileResult)
        if (fileName.length > 0) {
          // eslint-disable-next-line no-unused-vars
          let fileNameError = ''
          fileName.forEach(item => {
            fileNameError += `- ${item}\n`
          })
          this.$toast.error(`Tệp đính kèm bị trùng: \n ${fileNameError}`)
        }
        if (fileNameSize.length > 0) {
          // eslint-disable-next-line no-unused-vars
          let fileNameSizeError = ''
          fileNameSize.forEach(item => {
            fileNameSizeError += `- ${item}\n`
          })
          this.$toast.error(`Tệp đính kèm:\n ${fileNameSizeError} dung lượng lớn hơn 25 MB`)
        }
        if (filesNot.length > 0) {
          // eslint-disable-next-line no-unused-vars
          let filesNotError = ''
          filesNot.forEach(item => {
            filesNotError += `- ${item}\n`
          })
          this.$toast.error(`Tệp đính kèm:\n ${filesNotError} không đúng định dạng`)
        }
        if (filesAttck.length > 0) {
          // eslint-disable-next-line prefer-const
          let formData = new FormData()
          filesAttck.forEach(item => {
            formData.append('listFileData', item)
          })
          this.$axios.post(END_POINTS.UPLOAD_FILE.MULTI, formData, false).then(res => {
            if (res.data) {
              const data = res.data.map(item => ({
                tenFile: item.fileName,
                path: item.objectName,
              }))
              this.data.tepDinhKem.push(...data)
            }
            this.isLoadedFile = true
          })
        } else {
          this.isLoadedFile = true
        }
      } finally {
        this.isLoadedFile = true
      }
    },
    deleteFile(index) {
      this.data.tepDinhKem = this.data.tepDinhKem.filter((item, idx) => idx !== index)
    },
    changeXuLy() {
      this.data.allowIdentityId = null
      this.signature = null
      this.getNguoiXuLy()
      if (!this.data.command) {
        return
      }
      const xl = this.xlCbx.find(item => item.commandName === this.data.command)
      this.type = xl.type
      this.xacThucKySo = xl.xacThucKySo
      if (xl.type === 'YEU_CAU_BO_SUNG') {
        this.componentForm = 'ThongTinBoSung'
        this.dataFormComponent = [
          {
            tenGiayTo: null,
            banChinh: 0,
            banSao: 0,
            banPhoto: 0,
            dsHoSoGiayToFileDinhKem: [],
          },
        ]
      } else if (xl.type === 'TRINH_DUYET_GIA_HAN') {
        this.componentForm = 'ThongTinGiaHan'
        this.dataFormComponent = {
          hoSoId: this.hoSoId,
          ngayHenTraHienTai: new Date(this.detail.ngayHenTraKq),
          ngayHenTraMoi: null,
          soNgayGiaHan: 0,
          dsFileDinhKem: [],
          lyDoGiaHan: null,
        }
      } else if (['DUYET_GIA_HAN', 'TU_CHOI_GIA_HAN'].includes(xl.type)) {
        this.componentForm = 'ThongTinGiaHan'
        this.dataFormComponent = {
          hoSoId: this.hoSoId,
          ngayHenTraHienTai: new Date(this.detail.giaHanHoSo.ngayHenTraHienTai),
          ngayHenTraMoi: new Date(this.detail.giaHanHoSo.ngayHenTraMoi),
          soNgayGiaHan: this.detail.giaHanHoSo.soNgayGiaHan,
          dsFileDinhKem: this.detail.giaHanHoSo.dsFileDinhKem,
          lyDoGiaHan: this.detail.giaHanHoSo.lyDoGiaHan,
        }
      } else if (xl.type === 'TIEP_NHAN_BO_SUNG') {
        this.componentForm = 'ThongTinBoSung'
        this.dataFormComponent = this.detail?.hoSo_GiayToBoSungModels
      } else if (['KY_DUYET_GIAI_QUYET_HO_SO', 'KY_DUYET_TU_CHOI_GIAI_QUYET_HO_SO', 'TRA_KET_QUA'].includes(xl.type)) {
        this.componentForm = 'ThemKetQuaFile'
        this.dataFormComponent = this.detail.tepKetQuaHoSo
      } else {
        this.componentForm = null
      }
    },
    closePage() {
      this.$emit('close-page')
    },
    hideModal() {
      this.$bvModal.hide('bienNhanHoSo')
      this.$emit('close-page')
    },
    cancelForm() {
      if (this.type === 'BAN_GIAO_HO_SO') {
        this.closePage()
      }
    },
    downloadFile(data) {
      this.$axios.post(END_POINTS.UPLOAD_FILE.DOWNLOAD, { path: data.path }).then(res => {
        if (res.data?.succeeded) {
          // eslint-disable-next-line prefer-const
          let a = document.createElement('a')
          a.href = `data:application/octet-stream;base64,${res.data.fileData}`
          a.download = res.data.fileName
          a.setAttribute('target', '_blank')
          a.click()
        }
      })
    },
  },
}
</script>
