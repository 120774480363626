<template>
  <b-card
    no-body
    class="mb-0"
  >
    <div class="card-body">
      <div class="d-sm-flex justify-content-end mb-1">
        <button
          class="compact ui btn-primary button"
          @click="onPrewProcessing"
        >
          Xem luồng xử lý
        </button>
      </div>
      <good-table
        ref="vgTable"
        class="mb-1"
        :columns="columns"
        :rows="rows"
        :total="total"
        :selectable="false"
        :pagination="false"
        :is-loading="isLoading"
        @page-change="pageChange"
      >
        <template
          slot="custom-row"
          slot-scope="props"
        >
          <span v-if="props.props.column.field == 'tepDinhKem'">
            <div
              v-for="(item, index) in props.props.row.tepDinhKem"
              :key="index"
            >
              - <a
                href="javascript:;"
                @click="downloadFile(item)"
              >{{ item.tenFile }}</a>
            </div>
          </span>
          <span v-else-if="props.props.column.field == 'comment'">
            <!-- eslint-disable vue/no-v-html -->
            <div v-html="props.props.row.comment" />
            <!--eslint-enable-->
          </span>
        </template>
      </good-table>
      <div class="d-sm-flex justify-content-end">
        <button
          class="compact ui btn-primary button mr-1"
          @click="onPrintProcessing"
        >
          In chi tiết tiến độ xử lý
        </button>
        <button
          class="compact ui btn-danger button"
          @click="closePage"
        >
          Đóng
        </button>
      </div>
    </div>
    <common-modal
      ref="commomnModal"
      :title="title"
      :size="size"
    >
      <luong-xu-ly-schema />
      <div slot="footer">
        <div />
      </div>
    </common-modal>
    <iframe
      id="printf"
      name="printf"
      class="d-none"
    />
  </b-card>
</template>
<script>
import END_POINTS from '@/api/endpoints'
import {
  BCard,
} from 'bootstrap-vue'
import addCssSemantic from '@/mixins/mixins'
import GoodTable from '@/components/GoodTable.vue'
import commonModal from '@/modules/tiep-nhan-ho-so/components/modal/CommonModal.vue'
import LuongXuLySchema from '@/components/LuongXuLySchema.vue'
import * as dayjs from 'dayjs'

export default {
  name: 'ChiTietHoSo',
  components: {
    BCard,
    GoodTable,
    commonModal,
    LuongXuLySchema,
  },
  mixins: [addCssSemantic],
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      columns: [
        {
          label: 'STT',
          thClass: 'text-center custom-style vertical-align-middle',
          tdClass: 'text-center',
          width: '50px',
          field: 'STT',
          sortable: false,
        },
        {
          label: 'Người thực hiện',
          field: 'executedName',
          width: '200px',
          thClass: 'text-center vertical-align-middle',
        },
        {
          label: 'Người nhận',
          field: 'allowIdentityIdsName',
          thClass: 'text-center vertical-align-middle',
          width: '200px',
        },
        {
          label: 'Hành động',
          field: 'commandName',
          width: '200px',
          thClass: 'text-center vertical-align-middle',
        },
        {
          label: 'Ngày bắt đầu',
          field: 'beginStateTime',
          thClass: 'text-center vertical-align-middle',
          tdClass: 'text-center',
          width: '150px',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSSSSSSS',
          dateOutputFormat: 'dd/MM/yyyy HH:mm',
        },
        {
          label: 'Ngày dự kiến kết thúc',
          field: 'endDateExpired',
          thClass: 'text-center vertical-align-middle',
          tdClass: 'text-center',
          width: '150px',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss',
          dateOutputFormat: 'dd/MM/yyyy HH:mm',
        },
        {
          label: 'Ngày kết thúc thực tế',
          field: 'executedTransition',
          thClass: 'text-center vertical-align-middle',
          tdClass: 'text-center',
          width: '150px',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSSSSSSS',
          dateOutputFormat: 'dd/MM/yyyy HH:mm',
        },
        {
          label: 'Tệp đính kèm',
          field: 'tepDinhKem',
          thClass: 'text-center vertical-align-middle',
          width: '250px',
        },
        {
          label: 'Tiến trình xử lý',
          field: 'state',
          width: '150px',
          thClass: 'text-center vertical-align-middle',
        },
        {
          label: 'Ý kiến',
          field: 'comment',
          width: '150px',
          thClass: 'text-center vertical-align-middle',
        },
      ],
      rows: [],
      hoSoId: null,
      total: 0,
      isLoading: true,
      title: null,
      size: null,
    }
  },
  async created() {
    await this.loadScripts()
    this.hoSoId = this.$route.query.id
    this.getDataTienTrinhXuLy()
  },
  methods: {
    downloadFile(data) {
      this.$axios.post(END_POINTS.UPLOAD_FILE.DOWNLOAD, { path: data.path }).then(res => {
        if (res.data?.succeeded) {
          // eslint-disable-next-line prefer-const
          let a = document.createElement('a')
          a.href = `data:application/octet-stream;base64,${res.data.fileData}`
          a.download = res.data.fileName
          a.setAttribute('target', '_blank')
          a.click()
        }
      })
    },
    getDataTienTrinhXuLy() {
      this.isLoading = false
      try {
        this.$axios.get(`${END_POINTS.WORKFLOW_CUSTOM.TIEN_TRINH_XU_LY}/${this.hoSoId}`).then(res => {
          if (res.data?.code === 200) {
            this.rows = res.data.data
            this.total = res.data.data.length
          }
        })
      } finally {
        setTimeout(() => {
          this.isLoading = true
        }, 2000)
      }
    },
    pageChange() {

    },
    onPrintProcessing() {
      const newWin = window.frames.printf
      let printEle = `
        <style>
          @page {
            size: auto A4 landscape;
            margin: 3mm;
          }
        </style>
        <table style="width: 100%; border-collapse: collapse; border: 1px solid black;">
          <tr>
            <th style="border: 1px solid black; padding: 10px; width: 35px !important; text-align: center;">
              STT
            </th>
            <th style="border: 1px solid black; padding: 10px; text-align: center;">
              Người thực hiện
            </th>
            <th style="border: 1px solid black; padding: 10px; width: 100px !important; text-align: center;">
              Người nhận
            </th>
            <th style="border: 1px solid black; padding: 10px; width: 100px !important; text-align: center;">
              Hành động
            </th>
            <th style="border: 1px solid black; padding: 10px; width: 100px !important; text-align: center;">
              Ngày bắt đầu
            </th>
            <th style="border: 1px solid black; padding: 10px; width: 100px !important; text-align: center;">
              Ngày dự kiến kết thúc
            </th>
            <th style="border: 1px solid black; padding: 10px; width: 100px !important; text-align: center;">
              Ngày kết thúc thực tế
            </th>
            <th style="border: 1px solid black; padding: 10px; width: 100px !important; text-align: center;">
              Tệp đính kèm
            </th>
            <th style="border: 1px solid black; padding: 10px; width: 100px !important; text-align: center;">
              Tiến trình xử lý
            </th>
            <th style="border: 1px solid black; padding: 10px; width: 100px !important; text-align: center;">
              Ý kiến
            </th>
          </tr>
      `
      this.rows.forEach((item, index) => {
        printEle += `
          <tr>
            <td style="border: 1px solid black; padding: 10px; text-align: center;">
              ${index + 1}
            </td>
            <td style="border: 1px solid black; padding: 10px;">
              ${item.executedName ? item.executedName : ''}
            </td>
            <td style="border: 1px solid black; padding: 10px;">
              ${item.allowIdentityIdsName ? item.allowIdentityIdsName : ''}
            </td>
            <td style="border: 1px solid black; padding: 10px;">
              ${item.commandName ? item.commandName : ''}
            </td>
            <td style="border: 1px solid black; padding: 10px; text-align: center;">
              ${item.beginStateTime ? this.formatDate(item.beginStateTime) : ''}
            </td>
            <td style="border: 1px solid black; padding: 10px; text-align: center;">
              ${item.endDateExpired ? this.formatDate(item.endDateExpired) : ''}
            </td>
            <td style="border: 1px solid black; padding: 10px; text-align: center;">
              ${item.executedTransition ? this.formatDate(item.executedTransition) : ''}
            </td>
            <td style="border: 1px solid black; padding: 10px;">
              ${item.tepDinhKem && item.tepDinhKem.length ? this.formatStrFile(item.tepDinhKem) : ''}
            </td>
            <td style="border: 1px solid black; padding: 10px;">
              ${item.state ? item.state : ''}
            </td>
            <td style="border: 1px solid black; padding: 10px;">
              ${item.comment ? item.comment : ''}
            </td>
          </tr>
        `
      })
      printEle += '</table>'
      // eslint-disable-next-line prefer-template
      newWin.document.write(printEle)
      newWin.document.close()
      newWin.focus()
      newWin.print()
    },
    formatDate(date) {
      return date ? dayjs(date).format('DD/MM/YYYY') : null
    },
    formatStrFile(tepDinhKem) {
      let str = ''
      tepDinhKem.forEach(item => {
        str += `- ${item.tenFile} <br>`
      })
      return str
    },
    onPrewProcessing() {
      this.title = 'Luồng xử lý hồ sơ'
      this.size = 'xl'
      this.$refs.commomnModal.showModal()
    },
    closePage() {
      this.$emit('close-page')
    },
    loadScripts() {
      this.renderExternalScript('/assets/workflow/js/jquery.js').onload = () => {}
      this.renderExternalScript('/assets/workflow/js/semantic.min.js').onload = () => {}
      this.renderExternalScript('/assets/workflow/js/konva.min.js').onload = () => {}
      this.renderExternalScript('/assets/workflow/js/ace.js').onload = () => {}
      this.renderExternalScript('/assets/workflow/js/jquery.auto-complete.min.js').onload = () => {}
      this.renderExternalScript('/assets/workflow/js/workflowdesigner.js').onload = () => {}
      this.renderExternalLink('/assets/workflow/css/workflowdesigner.css').onload = () => {}
      this.renderExternalLink('/assets/workflow/css/semantic.min.css').onload = () => {}
    },
  },
}
</script>
